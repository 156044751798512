.sympTable {
  color: #646777;
  font-size: 13px;

  &__row {
    width: 100%;

    @media (min-width: 800px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_header {
      display: none;
      border-bottom: 1px solid $colorBorder;

      @media (min-width: 800px) {
        display: flex;
      }
    }

    &_even {
      background-color: #fafbfe;
    }
  }

  &__column {
    padding: 10px;

    &_first {
      //font-weight: bold;
      border-bottom: 1px solid $colorBorder;
    }

    @media (min-width: 800px) {
      flex: 1 1 auto;

      &_first {
        //width: 50px;
        min-width: 50px;
        flex-grow: 0;
        font-weight: normal;
        border: none;
      }
    }

    &_header {
      font-weight: bold;
    }

    &_date {
      @media (min-width: 800px) {
        min-width: 105px;
        max-width: 105px;
        flex-grow: 0;
      }
    }

    &_time {
      @media (min-width: 800px) {
        min-width: 65px;
        max-width: 65px;
        flex-grow: 0;
      }
    }

    &_temp {
      @media (min-width: 800px) {
        min-width: 50px;
        max-width: 50px;
        flex-grow: 0;
      }
    }

    &_count {
      @media (min-width: 800px) {
        //min-width: 105px;
        text-align: right;
        flex-grow: 0;
      }
    }

    & span {
      font-weight: bold;
      padding-right: 10px;

      @media (min-width: 800px) {
        display: none;
      }
    }
  }
}
