.subject {
  width: 100%;
  max-width: 873px;
  padding-bottom: 20px;
  color: $colorText;
  transition: none;

  @media (min-width: 992px) {
    display: flex;
    align-items: stretch;
    flex-direction: row;
  }

  &_width {
    max-width: 600px;
    padding-bottom: 60px;
  }

  &__block {
    &_width {
      @media (min-width: 992px) {
        width: 362px;
        min-width: 362px;
      }
    }
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 10px 14px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    transition: none;
    @include themify($themes) {
      background-color: themed('colorPrimary');
      border-color: themed('colorPrimary');
    }

    & svg {
      line-height: 1;
      height: 24px;
      font-size: 24px;
    }

    & span {
      display: flex;
      align-items: center;
      padding-left: 10px;
      margin-left: 10px;
      border-left: 1px solid white;
    }

    &_primary {
      @include themify($themes) {
        background-color: themed('colorPrimary');
        border-color: themed('colorPrimary');
      }

      &:before {
        @include themify($themes) {
          background-color: themed('colorPrimary-hover');
        }

      }

      &:hover, &:focus, &:active, &:active:focus {
        @include themify($themes) {
        background-color: themed('colorPrimary');
        border-color: themed('colorPrimary');
        }
      }
    }

    &_gray {
      @include themify($themes) {
        border-color: themed('colorAdditional');
        background-color: themed('colorAdditional');
      }

      &:hover, &:focus, &:active, &:active:focus {
        @include themify($themes) {
          border-color: themed('colorAdditional');
          background-color: themed('colorAdditional');
        }
      }
    }

    &_disabled {
      @include themify($themes) {
        border-color: #f2f4f7 ;
        background-color: #f2f4f7;
      }
      cursor: not-allowed !important;

      &:hover, &:focus, &:active, &:active:focus {
        border-color: #f2f4f7;
        background-color: #f2f4f7;
      }
    }
  }

  &__container {
    border-radius: 0;
    padding-bottom: 34px;

    &_gray {
      background-color: #FAFBFE;
    }
  }

  &__title {
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: bold;
    @include themify($themes) {
      color: themed('textColor')
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: initial;

    &_odd {
      background-color: #FAFBFE;
    }

    &_selected {
      background-color: #E5E8F1;
    }

    &_marked {
      @include themify($themes) {
        background-color: themed('markedColor');
      }
      //background-color: rgba(129, 186, 247, 0.3);
    }

    &_bold {
      font-weight: bold;
    }

    &_hover {
      &:hover {
        background-color: $colorBubbleActive;
      }
    }
  }

  &__column {
    width: 33.3%;
    text-align: center;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    padding: 0 10px;
    color: #656776;

    @media (max-width: 992px) {
      width: 40%;
    }

    &_first {
      width: 33.3%;
      white-space: nowrap;
    }

    &_bold {
      font-weight: bold;
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    &_blue {
      @include themify($themes) {
        color: themed('colorPrimary');
      }
    }

    & svg {
      font-size: 24px;
      height: 23px;
      line-height: 1;
    }

    &_button {
      cursor: pointer;
    }
  }
}
