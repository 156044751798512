.cls {
  &__column {
    width: 50%;
    display: flex;
    padding: 13px;
    align-items: center;
    border-bottom: 1px solid $colorBorder;
    color: $colorText;
    font-size: 16px;

    @media (min-width: 992px) {
      width: 40%;
    }

    &_action {
      @media (min-width: 992px) {
        width: 20%;
      }
      & .cls__iconInner {
        display: none;
      }
    }
  }

  &__headerIcon {
    color: $colorPrimary;
    line-height: 1;
    font-size: 23px;
    padding-right: 14px;
  }

  &__icon {
    margin-right: 14px;
    color: $colorPrimary;

    &_green {
      color: $color-accent;
    }

    &_red {
      color: $color-red
    }
  }

  &__inner {
    border: none;
    outline: none;
    box-shadow: none;

    &_empty {
      font-weight: 300;

      & h5 {
        font-weight: inherit;
      }
    }

    &:hover {
      background-color: #FAFBFE;
    }

    &:focus {
      background-color: #E5E8F1;

      & .cls__column_action {
        & .cls__iconInner {
          display: block;
        }
      }
    }
  }
}
