.subjectSymp {
  max-width: 602px;
  padding-bottom: 20px;

  &__block {
    margin-bottom: 10px;

    &_last {
      margin-bottom: 0;
    }

    & .panel__body .panel__content {
      padding: 15px 24px 30px;

      @media (min-width: 1500px) {
        padding: 15px 24px 30px;
      }
    }

    &_padding {
      .panel__content {
        padding: 0 26px;
      }
    }
  }
}
