.card {
  display: block;
  width: 100%;
  padding-bottom: 30px;
  //min-height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }

  @media (min-width: 922px) {
    display: flex;
  }

  & .card__icon {
    &:hover {
      opacity: 0.7;
    }
  }

  & .card__title {
    &:hover {
      opacity: 0.7;
    }
  }

  &__headerTotal {
    color: white;
    display: block;
    font-size: 14px;
    line-height: normal;
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 5px;

    @include themify($themes) {
      background-color: themed('colorPrimary');
    }
  }
}

.card-body {
  padding: 30px 15px;
  min-height: 100%;
  border-radius: 5px;
  background-color: $colorBackground;

  @media (min-width: 1400px) {
    padding: 30px;
  }
}

.card__title {
  font-size: 18px;

  @include themify($themes) {
    color: themed('colorPrimary');
  }
}

.card__icon {
  font-size: 97px;
  line-height: 1;
  margin-bottom: 27px;

  @include themify($themes) {
    color: themed('colorPrimary');
  }
}

.card__header {
  h5 {
    font-size: 18px;
    margin-left: 7px;

    @include themify($themes) {
      color: themed('colorPrimary');
    }
  }

  svg {
    font-size: 32px;

    @include themify($themes) {
      color: themed('colorPrimary');
    }
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}
