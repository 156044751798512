.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  margin-top: -80px;

  .account__title {
    & span {
      @include themify($themes) {
        color: themed('colorPrimary');
      }
    }
  }

  &__forgot-password {
    border-top: 1px solid $colorBorder;
    width: 100%;
    margin-top: 36px;
  }

  &__forgot-passLink {
    font-size: 13px;
    line-height: 15px;
    color: $color-additional;
    text-align: center;
    padding-top: 18px;
    cursor: pointer;
  }
}

.account__wrapper {
  margin: auto;
  padding: 10px;
}

.account__card {
  background-color: white;
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
}

.account__forgot-password {
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__head {
  margin-bottom: 30px;
  padding-left: 10px;
  @include themify($themes) {
    border-left: 4px solid themed('colorPrimary');
  }
  border-left: 4px solid $colorPrimary;
}

@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}
