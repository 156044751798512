.chat {
  max-width: 803px;

  &__wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    padding: 13px 15px 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F2F4F7;
  }

  &__title {
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    color: $colorText;
  }

  &__icon {
    color: $color-additional;
    font-size: 23px;
    line-height: 1;
    cursor: pointer;

    &_back {
      min-width: 0;
      padding: 4px 7px !important;

      @media (min-width: 992px) {
        display: none;
      }
    }
  }

  &__scroll {
    overflow-y: scroll;
    flex-grow: 1;
    border-bottom: 1px solid #F2F4F7;
  }

  &__container {
    padding: 21px 15px 15px;
    color: $colorText;
    height: 100%;
  }

  &__footer {
    padding: 12px 15px;
  }

  &__form {
    width: 100%;
    position: relative;
  }

  &__input {
    width: 100%;
    padding: 9px 14px;
    background-color: #F2F4F7;
    border-radius: 50px;
    font-size: 14px;
    color: #B6B9C9;
    border: none;
    outline: none;
  }

  &__submitButton {
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    right: 5px;
    color: #A0A0A1;
    bottom: 50%;
    transform: translateY(50%);
    font-size: 16px;
    cursor: pointer;
  }

  &__card {
    background: #F7F8FA;
    border-radius: 0 32px 32px 32px;
    font-size: 14px;
    line-height: 16px;
    color: $colorText;
    padding: 24px;
    margin-bottom: 24px;
    max-width: 600px;

    &_right {
      border-radius: 32px 0 32px 32px;
      background: #F3F5FE;
    }
  }

  &__cardInner {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    &_right {
      justify-content: flex-end;
    }
  }
}
