.topbar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 101;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  background: $colorBackground;

  &__wrapper {
    position: relative;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 0 27px;
    padding-right: 20px;
  }

  &__left {
    align-self: center;

    h1 {
      font-size: 24px;
      line-height: 28px;
      @include themify($themes) {
        color: themed('colorPrimary');
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__menu {
    display: flex;
    height: 100%;
  }

  &__navLink {
    padding: 0 7px;
    list-style: none;
    color: #b1c3c8;
    font-size: 20px;
    display: flex;
    align-items: center;
    height: 100%;

    @include themify($themes) {
      color: themed('iconColor');
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
