.table {

  &:last-child {
    margin-bottom: 0;
  }

  tbody td, th {
    border: none;
    padding: 10px;
    @include themify($themes) {
      color: themed('colorTextAdditional');
    }
  }

  thead th {

      color: $colorText;
      border-bottom-color: $colorBorder;
  }

  .badge {
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 400;

    &.badge-success {
      background-color: $color-accent;
    }

    &.badge-warning {
      background-color: $color-yellow;
    }

    &.badge-primary {
      background-color: $colorPrimary;
    }

    &.badge-danger {
      background-color: $color-red;
    }

    &.badge-disabled {
      background-color: #dddddd;
    }
  }

  &.table--bordered {

    tr {
        border-bottom: 1px solid $colorBorder;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.table--head-accent {

    thead {
      @include themify($themes) {
        color: themed('colorTextAdditional');
      }
        background-color: $colorHover;

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {

    tbody tr:nth-of-type(odd) {
        background-color: $colorHover;
    }
  }

  &.table-hover {

    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
          background-color: $colorBackgroundBody;
      }
    }
  }
}
