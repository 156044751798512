.color-picker {

  .color-picker__color {
    margin: auto auto auto 0;
    padding-left: 10px;
  }

  &__wrapper {
    position: absolute;
  }

  .color-picker__button {
    height: 32px;
    width: 160px;
    display: flex;
    cursor: pointer;
    transition: all 0.3s;
    background: transparent;
    padding: 0;

    border: 1px solid $colorFieldsBorder;

    &.active {
      border-color: $color-accent;
    }
  }

  .color-picker__color-view {
    height: 30px;
    width: 50px;
  }
}
