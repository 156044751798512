.card {
  .card-body {
    .reactTable {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include themify($themes) {
        color: themed('colorText');
      }

      .rt-td {
        padding: 7px 5px;
        width: auto !important;
        flex: 1 1 auto !important;
      }

      .rt-tr.-odd {
        background-color: #fafbfe;
      }

      .rt-tr.-selected {
        background-color: #E5E8F1;
      }

      .rt-tr {
        display: flex;

        &:hover {
          background-color: $colorBubbleActive;
        }
      }

      .-loading {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0;

        &.-active {
          display: block;
        }
      }

      & .rt-noData {
        padding: 0;
        position: absolute;
        left: 30px;
        top: 70px;
      }

      .rt-tbody {
        margin-top: 10px;
      }

      .rt-tr-group {
        cursor: pointer;
      }

      .rt-tbody {
        min-width: 0 !important;
      }

      .rt-table {
        margin-bottom: 20px;
      }
    }
  }
}
