.adminChat {
  justify-content: center;
  height: calc(100vh - 80px);
  height: calc((var(--vh, 1vh) * 100) - 80px);
  overflow: hidden;

  & .chat__scroll {
    height: calc(100vh - 210px);
    height: calc((var(--vh, 1vh) * 100) - 210px);
  }

  &__tableWrapper {
    flex-grow: 1;

    @media(min-width: 992px) {
      flex-grow: 0;
    }
  }

  &__table {
    @media(min-width: 992px) {
      max-width: 360px;
    }
  }
}
