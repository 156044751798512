.subAdminTable {
  .ReactTable .rt-tr {
    font-size: 13px;
  }

  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-shrink: 1;
    //max-width: 100%;
  }

  @media (min-width: 1400px) {
   // width: auto;
  }

  &__column {
    @media (min-width: 1200px) {
      flex: 1 1 auto;
      width: calc(100%/3);
      max-width: 1204px;
    }
  }

  &__inner {
    @media (min-width: 1200px) {
      display: flex;
      flex: 1 1 auto;
      width: calc(100% - 100%/3);
    }

    @media (min-width: 1400px) {
      width: auto;
    }
  }

  &__form {
    margin: 0 auto;
    max-width: 600px;

    @media (min-width: 1200px) {
      margin: 0;
      width: auto;
      max-width: 100%;
      flex: 1 1 auto;
      min-height: 1192px;
      min-width: 465px;
    }

    @media (min-width: 1360px) {
      min-width: 554px;
    }

    @media (min-width: 1400px) {
      min-width: 584px;
    }
  }

  &__wrapper {
    width: 100%;
    min-width: 275px;
    max-width: 600px;
    margin: 0 auto;

    @media (min-width: 1200px) {
      width: auto;
      margin: 0;
      margin-right: 30px;
    }
  }

  &_width {
    & .subAdminTable__column {
      @media (min-width: 1200px) {
        width: calc(100%/4);
      }
    }

    & .subAdminTable__inner {
      @media (min-width: 1200px) {
        width: calc(100% - 100%/4);
      }
    }
  }
}
