.load {
      width: 100vw;
      display: flex;
      align-items: center;
      position: fixed;
      background: transparent;
      z-index: 1000;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;

}
