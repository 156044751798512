.pagination {
  flex-wrap: wrap;

  &__text {
    color: $color-additional;
    margin-left: 10px;
  }
}

.pagination__link {
  background: transparent;
  border: none;
  color: $color-additional;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;
  margin: 0 5px;

  &:hover {
    color: $colorPrimary;
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
    height: 10px;
  }

  &.pagination__link--arrow {
    cursor: pointer;
    border-radius: 50% !important;
    background-color: #F2F4F7 !important;
    width: 30px;
    height: 30px;
    font-size: 17px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    &:hover {
      background: $colorPrimary;
      color: white;

      svg {
        fill: white;
      }
    }
  }
}

.pagination__item {

  &.active .pagination__link {
    background-color: transparent;
    font-weight: bold;
      color: $colorText;

  }

  &.disabled {

    .pagination__link svg {
      opacity: .2;
    }
  }
}

.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: $color-additional;
}

.pagination__wrap {
  display: flex;
  margin-top: 15px;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
}

.pagination-info {
  color: $color-additional;
  font-size: 12px;
  margin: auto 0 auto 10px;
}
