.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    //max-width: 1630px;
  }
}

.wrapper {
    background: $colorBackgroundBody;


  &.wrapper--full-width {

    .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.top-navigation {

    .container__wrap {

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 80px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  iframe {
    width: 100%;
    height: 100vh;
  }
}
