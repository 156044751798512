.subAdmin {
  .ReactTable {
    & .rt-tr-group {
      max-height: 50px;
      cursor: pointer;
    }
  }

  & > .card {
    min-height: calc(100vh - 236px);
  }



  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $colorText;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;

    & svg {
      height: 24px;
      font-size: 24px;
      margin-right: 23px;
    }
  }

  &__tabIcon {
    font-size: 24px;
  }
}
