.form {
  input, textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    -webkit-appearance: none;
    border-radius: 0;

    &::-webkit-input-placeholder {
      color: black;
    }
    &::-moz-placeholder {
      color: black;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: black;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: black;
    }

    border: 1px solid $colorFieldsBorder;

    .form__form-group {
      text-align: left;
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    &[disabled] {

      background: $colorBackgroundBody;

      &:focus, &:active {
        border-color: $colorBorder;
      }
    }

    &:focus, &:active {
      outline: none;
      @include themify($themes) {
        border-color: themed('colorPrimary');
      }
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {

    .form__form-group {
      @include themify($themes) {
        color: themed('colorText');
      }
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }

      &_red {
        @include themify($themes) {
          color: themed('colorRed');
        }
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {

      .form__form-group-label {
        width: 120px;

        &_red {
          @include themify($themes) {
            color: themed('colorRed');
          }
        }
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description, .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      @include themify($themes) {
        color: themed('colorText');
      }
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      @include themify($themes) {
        color: themed('colorText');
      }
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__select-border {
    border: 1px solid $colorBorder;
  }

  .form__form-group-select {
    position: relative;
    flex: 1 1 auto;
    font-size: 12px;

    .Select__control {
      height: 32px;
      min-height: 0;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;
      border-color: transparent;
    }
    .Select__single-value {
      max-width: calc(100% - 36px);
    }

    .Select__indicator-separator {
      display: none;
    }

    .Select__indicator {
      padding: 0 8px;
      border: none;
    }

    .Select__input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select__value-container, .Select__value-container--is-multi {
      height: 32px;
      position: static;
      overflow: hidden;
      align-items: flex-start;

      .Select__input {
        position: absolute;
        width: 0;
      }

      .Select__multi-value {
        @include themify($themes) {
          color: themed('colorText') !important;
          border: 1px solid themed('colorPrimary')
        }
        background: transparent;

        & > div {
          padding: 2px 5px;
        }
      }

      .Select__multi-value__remove {
        @include themify($themes) {
          border-left: 1px solid themed('colorPrimary');
          color: themed('colorPrimary');
        }
        border-radius: 0;
        cursor: pointer;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      border: 1px solid $colorBorder;
      background: $colorBackground;

    }

    @keyframes open {
      0% {
        max-height: 0
      }
      100% {
        max-height: 200px
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;
      background: $colorBackground;
      @include themify($themes) {
        color: themed('colorText');
      }

      &.is-focused {
        background: $colorHover;
      }
    }

    .Select__control {
      box-shadow: none;
      background: transparent;
      &_is--focused, &_is--focused:not(.is-open) {
        border-color: $colorPrimary;
        box-shadow: none;
        background: transparent;
      }
    }

    & .MuiInputBase-root {
      height: 32px;
      border: 1px solid $colorFieldsBorder;
      padding: 5px 10px;

      &:before {
        display: none;
      }

      & .MuiSelect-select:focus {
        background-color: transparent;
      }

      &:after {
        display: none;
      }
    }

    &_placeholder {
      font-size: 12px;
      color: black;
    }

    .MuiSvgIcon-root {
      display: none;
    }

    .MuiSelect-selectMenu {
      @include themify($themes) {
        color: themed('colorText');
      }
      font-size: 12px;
      padding: 0;
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }

    .form__form-group-select-close {
      font-size: 16px;
    }
  }

  @keyframes open {
    0% {
      max-height: 0
    }
    100% {
      max-height: 200px
    }
  }

  &_filter {

    & .form__form-group {
      @include themify($themes) {
        color: themed('colorText');
      }
      padding: 0 5px;
      margin-bottom: 0 !important;

      &:first-child {
        padding-left: 0;
        & .form__form-group-input-wrap {
          & input {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.form__form-group-select-all {
  & span {
    font-weight: bold !important;
  }
}

.form__form-group-select-icon {
  display: flex;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  align-items: center;
}

.form__form-group-select-filter {
  cursor: pointer;
  margin-right: 8px;
}

.form__select-wrap {
  border: 1px solid $colorBorder;
  display: flex;
  align-items: center;
  padding-right: 10px;
  height: 32px;
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
}

.form__form-group {
  text-align: left;
  @include themify($themes) {
    color: themed('colorText');
  }
  margin-bottom: 24px;
  //width: 100%;
  flex-grow: 1;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.form__form-group--address {
  @include themify($themes) {
    color: themed('colorText');
  }
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  @include themify($themes) {
    color: themed('colorText');
  }

  span {
    color: #dddddd;
  }

  &_red {
    @include themify($themes) {
      color: themed('colorRed');
    }
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  background: $colorFieldsBorder;
  border: 1px solid $colorFieldsBorder;

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-file {

  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    border: 1px solid $colorFieldsBorder;
    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {
      background: $colorFieldsBorder;
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;
  color: #999999;
  min-width: 32px;
  background: $colorFieldsBorder;
  border: 1px solid $colorFieldsBorder;

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
  position: relative;
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {

  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 24px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.form__form-group-input-wrap {
  width: 100%;

  &_success {
    & input {
      &:focus, &:active {
        outline: none;

        @include themify($themes) {
          border-color: themed('colorPrimary');
        }
      }
    }
  }

  &_error {
    & input, textarea {
      border: 1px solid $color-red !important;
    }
  }

  &_selectError {
    & .select {
      outline: 1px solid $color-red !important;
    }
  }
}

.form__from-fields-wrapper {
  display: flex;
  margin-bottom: 24px;
}

.sortIcon {
  position: absolute;
  right: 16px;
  cursor: pointer;
  bottom: 50%;
  transform: translateY(50%);
}

.btn:focus, .btn.focus {
  box-shadow: none;
}

button:focus {
  outline: none;
}

.MuiMenu-paper {
  min-width: auto !important;
}

.MuiMenuItem-root {
  background-color: transparent;
  max-width: 350px;
  white-space: normal !important;
  display: flex;
  align-items: flex-start !important;
  line-height: 1 !important;
  min-height: auto !important;
  @include themify($themes) {
    color: themed('colorText') !important;
  }

  &:hover {
    background-color: transparent;
  }
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}
.MuiTypography-root {
  font-size: 12px !important;
}

.MuiButtonBase-root {
  padding: 0 !important;
}

.MuiIconButton-label {
  margin-right: 10px;
}

.MuiIconButton-colorPrimary:hover {
  background-color: transparent !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  svg {
    @include themify($themes) {
      color: themed('colorPrimary');
    }
  }
}

.MuiSvgIcon-root {
  color: $colorBorder;
}

.MuiListItem-button:hover {
  background-color: $colorHover !important;
}

.MuiListItem-button {
  transition: none !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
  padding: 5px !important;
}

.MuiButtonBase-root {
  &:hover {
    background-color: transparent !important;
  }
}
