$themes: (
        blue: (
                colorText: #646777,
                colorTextAdditional: #999999,
                colorAccent: darken(#4ce1b6, 10%),
                colorAccentHover: darken(#4ce1b6, 10%),
                colorPrimary: darken(#70bbfd, 10%),
                colorAdditional: #d0c6c6,
                iconColor: #b1c3c8,
                borderRadioColor: #dddddd,
                colorRed: darken(#ff4861, 10%),
                colorYellow: darken(#f6da6e, 10%),
                markedColor: rgba(129, 186, 247, 0.3),
        ),
        purple: (
                colorPrimary: rgb(132,92,211),
                iconColor: #495057,
                colorAccent: #3abf94,
                colorAccentHover: #3abf94,
                colorText: #495057,
                colorAdditional: #B7B9C7,
                colorTextAdditional: #999999,
                borderRadioColor: #c1ccd3,
                colorRed: #f55d5d,
                colorYellow: #ffc247,
                markedColor: rgba(132, 92, 212, .1),
        )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$colorBackground: white;
$colorBackgroundBody: #f2f4f7;
$colorText: #646777;
$colorTextAdditional: #646777;
$colorHover: #fafbfe;
$colorBorder: #eff1f5;
$colorIcon: #dddddd;
$colorBubbleActive: rgba(234, 238, 255, 0.6);
$colorScrollbar: #B4BFD0;
$colorFitness: #646777;
$colorFieldsBorder: #f2f4f7;

$color-accent: darken(#4ce1b6, 10%);
$color-accent-hover: darken(#4ce1b6, 10%);
$color-additional: #d0c6c6;
$color-additional-hover: #d0c6c6;
$color-yellow: darken(#f6da6e, 10%);
$color-yellow-hover: darken(#f6da6e, 15%);

$color-violet: #c88ffa;

$color-red: darken(#ff4861, 10%);
$color-red-hover: darken(#ff4861, 15%);

$colorPrimary: darken(#70bbfd, 10%);
$colorPrimary-hover: darken(#70bbfd, 15%);

$color-gray: #787985;
